import React from 'react';
import PropTypes from 'prop-types';

/**
 * Collapsible List Item
 * @description A single list item to display inside Collapsible component
 */

export default function CollapsibleListItem ({
  label,
  icon,
  handleClick,
  className
}) {
  return (
    <li className={`collapsible-list-item ${className}`}>
      <button onClick={handleClick}>
        {icon} {label}
      </button>
    </li>
  );
}

CollapsibleListItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  handleClick: PropTypes.func,
  className: PropTypes.string
};
