import React, { useState } from 'react';
import Cite from 'citation-js';
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faFileAlt,
  faDownload
} from '@fortawesome/free-solid-svg-icons';

import Layout from 'components/Layout';
import CollapsibleListItem from 'components/CollapsibleListItem';
import Modal from 'components/Modal';
import productData from 'data/products.json';
import globalCitations from 'data/globalCitations.json';
import usCitations from 'data/usCitations.json';
import hdCitations from 'data/hdCitations.json';
import { capitalizeFirstLetter } from 'lib/util';
import LandScanSplash from '../assets/images/landscan_imagery_BG.png';

const CitationPage = () => {
  const [citation, setCitation] = useState({
    label: 'LandScan',
    citation: ''
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const baseUrl = `https://${process.env.CNAME}/citations`;

  let modalContent;
  function downloadCitation (product, variation) {
    const { country, version = 'v1', year } = variation; // if this doesn't work it's b/c it needs the var time
    let apiUrl = '';
    if (product === 'global') {
      apiUrl = `${baseUrl}/${product}/${year}/lsglobal_${year}.ris`;
    }
    if (product === 'hd') {
      const urlCountry = country.replace(/\s/g, '').toLowerCase();
      apiUrl = `${baseUrl}/${product}/${urlCountry}/${version}/lshd_${urlCountry}_${version}-0.ris`;
    }
    if (product === 'us') {
      apiUrl = `${baseUrl}/${product}a/${year}/lsusa_${year}.ris`;
    }
    return apiUrl;
  }

  async function handleViewCitation (
    product,
    variation = {
      country: '',
      version: 'v1',
      year: 2020,
      time: '',
      region: ''
    }
  ) {
    setModalOpen(true);
    setModalLoading(true);
    setCitation({
      label: undefined,
      citation: undefined,
      download: undefined
    });
    let doiUrl;
    // Global DOIs
    if (product === 'global') {
      setCitation({
        label: `LandScan Global ${variation?.year}`
      });
      doiUrl = `https://doi.org/${globalCitations[variation.year].doi}`;
    } else if (product === 'us') {
      // US DOIs
      setCitation({
        label: `LandScan USA ${variation?.year}`
      });
      doiUrl = `https://doi.org/${usCitations[variation.year].doi}`;
    } else if (product === 'hd') {
      // HD DOIs
      setCitation({
        label: `LandScan HD ${capitalizeFirstLetter(variation?.country)} ${
          variation.version ? `(${variation.version})` : ''
        }`
      });
      if (!variation.version) {
        doiUrl = `https://doi.org/${hdCitations[variation.country].doi}`;
      } else {
        // with multiple versions
        doiUrl = `https://doi.org/${
          hdCitations[variation.country + '-' + variation.version].doi
        }`;
      }
    }
    const citation = await Cite.async(doiUrl);
    const formattedCitation = citation.format('bibliography', {
      format: 'html',
      template: 'apa',
      lang: 'en-US'
    });
    setCitation(currentCitation => ({
      ...currentCitation,
      citation: formattedCitation,
      download: downloadCitation(product, variation)
    }));
    setModalLoading(false);
  }

  const globalList = productData.yearOptions.map((year, i) => (
    <CollapsibleListItem
      label={`LandScan Global (${year.value})`}
      icon={<FontAwesomeIcon icon={faFileAlt} />}
      key={`${i}-${year}`}
      handleClick={() =>
        handleViewCitation('global', {
          country: 'global',
          year: parseInt(`${year.value}`)
        })
      }
    />
  ));

  const hdList = productData.hdCountryOptions.map((country, i) => (
    <CollapsibleListItem
      label={`LandScan HD ${country.label} ${
        country.version ? country.version : ''
      }`}
      icon={<FontAwesomeIcon icon={faFileAlt} />}
      key={`${i}-${country}`}
      handleClick={() =>
        handleViewCitation('hd', {
          country: `${country.label}`,
          version: country.version
        })
      }
    />
  ));

  // Generating a custom list of US Citations. US Citations are only unique by year,
  // unlike Metadata which is unique by year and region
  const usList = productData.usYearOptions.map(year => {
    const region = productData.usRegions[0];
    return (
      <CollapsibleListItem
        label={`Landscan USA (${year})`}
        icon={<FontAwesomeIcon icon={faFileAlt} />}
        key={`${year}`}
        handleClick={() =>
          handleViewCitation('us', {
            year: year,
            time: region.time,
            region: `${region.value}`
          })
        }
      />
    );
  });

  return (
    <Layout>
      <section className="subpage">
        <div
          className="subpage__header"
          style={{
            backgroundImage: `url(${LandScanSplash})`
          }}
        >
          <h1>Citations</h1>
        </div>
        <div className="subpage__content">
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
          <Collapsible
            className="collapsible-panel global-panel"
            trigger={[
              'LandScan Global Citations',
              <FontAwesomeIcon key={1} icon={faChevronDown} />
            ]}
          >
            {globalList}
          </Collapsible>
          <Collapsible
            className="collapsible-panel hd-panel"
            trigger={[
              'LandScan HD Citations',
              <FontAwesomeIcon key={2} icon={faChevronDown} />
            ]}
          >
            {hdList}
          </Collapsible>
          <Collapsible
            className="collapsible-panel us-panel"
            trigger={[
              'LandScan USA Citations',
              <FontAwesomeIcon key={3} icon={faChevronDown} />
            ]}
          >
            {usList}
          </Collapsible>
        </div>
        {/* if toggle is true display the modal with the citation on it */}
        {modalOpen && (
          <Modal
            className="citation"
            isOpen={modalOpen}
            toggleModal={() => setModalOpen(!modalOpen)}
            header={<h2>{citation?.label}</h2>}
          >
            {modalLoading ? (
              <p className="align-center">
                <span className="spinner" />
              </p>
            ) : (
              <div className="citation-block">
                <h5>APA Format:</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: citation?.citation
                  }}
                />
              </div>
            )}
            {citation?.download && (
              <div className="modal-footer">
                <a href={citation.download}>
                  <FontAwesomeIcon icon={faDownload} /> .RIS
                </a>
              </div>
            )}
          </Modal>
        )}
      </section>
    </Layout>
  );
};

export default CitationPage;
